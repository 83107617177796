import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/CCG_main_logo_WO_large.png"

const Header = ({ siteTitle }) => (
  <header className="header current-career-vacancies" id="header">
    <nav className="_nav_wrapper">
      <div className="row">
        <div className="col-12 col-4-m">
          <div className="logo">
            <a
              href="https://www.chigroup.ac.uk/"
              id="_nav_main_logo"
              className="_nav_wrapper_left _nav_wrapper_logo"
            >
              <img src={Logo} alt="Chichester College Group" />
            </a>
          </div>
        </div>

        <div className="col-12 col-8-m">
          <div className="_nav_wrapper_right _nav_wrapper_menu">
            <div className="_nav_wrapper_menu_items">
              <div className="_nav_wrapper_menu_item_wrap">
                <a
                  href="/"
                  className="_nav_wrapper_menu_item_link _nav_wrapper_menu_item_link_bth _nav_wrapper_menu_item_link_bth_desktop"
                >
                  Careers Home
                </a>
              </div>
              <div className="_nav_wrapper_menu_item_wrap">
                <a
                  href="https://www.chigroup.ac.uk/about/careers/information-for-applicants"
                  className="_nav_wrapper_menu_item_link _nav_wrapper_menu_item_link_bth _nav_wrapper_menu_item_link_bth_desktop"
                >
                  Information for Applicants
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
