/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import Cookies from "./cookies"
import "./base.css"
import "./theme.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
      className="main-app-wrap"
      style={{
        backgroundImage: "url('/CC1854_vacancy_filler_background_image.jpg')",
      }}
    >
      <Header siteTitle={data.site.siteMetadata.title}></Header>
      <div className="app-layout">
        <main>
          {children}

          <div className="footer-layout">
            <Footer />
            <Cookies />
          </div>
        </main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
