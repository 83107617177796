import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle }) => (
  <footer>
    <div class="footer-container">
      <div class="region region-footer">
        <div
          id="block-menu-menu-college-group-footer-menu"
          class="block block-menu"
        >
          <div class="content">
            <ul class="menu">
              <li class="first leaf key-background-color-light key-background-color-light-hover">
                <a href="https://brinsbury.ac.uk/" class="brinsbury">
                  Brinsbury
                </a>
              </li>{" "}
              <li class="leaf key-background-color-light key-background-color-light-hover">
                <a href="https://chichester.ac.uk/" class="chichester">
                  Chichester
                </a>
              </li>{" "}
              <li class="leaf key-background-color-light key-background-color-light-hover">
                <a href="https://www.crawley.ac.uk/" class="crawley">
                  Crawley
                </a>
              </li>{" "}
              <li class="leaf key-background-color-light key-background-color-light-hover">
                <a href="https://www.worthing.ac.uk/" class="worthing">
                  Worthing
                </a>
              </li>{" "}
              <li class="leaf key-background-color-light key-background-color-light-hover">
                <a
                  href="http://www.firststepschildcare.co.uk/"
                  class="first-step"
                >
                  First step
                </a>
              </li>{" "}
              <li class="last leaf key-background-color-light key-background-color-light-hover">
                <a href="http://www.anglia.org/#" class="anglia">
                  Anglia
                </a>
              </li>
            </ul>
          </div>
        </div>{" "}
        <div id="block-block-1" class="block block-block">
          <div class="content"></div>
        </div>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
